import { useMemo, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
//toast
import { toast } from "react-toastify";
import useTranslation from "../hooks/useTranslation";
//mui
import {
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

//formik
import * as Yup from "yup";
import { useFormik } from "formik";

//services
import { login } from "../graphql/services";

import Spinner from "../components/Spiner";

//store
import { useDispatch } from "react-redux";
import { login as saveToken } from "../store/slices/userSlice";

//jwt auth
import jwt from "jwt-decode";
import { useSelector } from "react-redux";

const AuthForm = () => {
  const { t } = useTranslation();

  //hooks
  const dispatch = useDispatch();
  const router = useRouter();
  const { redirect, freelancerId } = router.query;
  const USER = useSelector((state) => state.auth.user);
  //password eye
  const [showPassword, setShowPassword] = useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  //form
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email"
      )
      .required("Email est nécessaire à remplir"),
    psw: Yup.string().required("le mot de passe est nécessaire à remplir"),
  });

  const initialValues = useMemo(
    () => ({
      email: "",
      psw: "",
    }),
    []
  );
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      loginClient(values);
    },
  });

  const loginClient = async (variables) => {
    const { r } = router.query;
    setLoading(true);
    const { email, psw: password } = variables;
    const { data, error, message } = await login({
      credentials: {
        email,
        password,
      },
    });
    setLoading(false);
    if (error) {
      if (message === "User not found") {
        toast.info(t("Utilisateur non trouvé"), {
          autoClose: 3000,
          hideProgressBar: true,
        });
      } else if (message === "Invalid credentials") {
        toast.info(t("Mot de passe incorrecte"), {
          autoClose: 3000,
          hideProgressBar: true,
        });
      } else if (message === "Email verification required") {
        toast.info(t("Vous devez activer votre compte"), {
          autoClose: 3000,
          hideProgressBar: true,
        });
        router.push("/userReactivation");
      } else if (message === "User is not verified") {
        toast.info(
          t("L'utilisateur n'est pas encore vérifié par l'administration"),
          {
            autoClose: 5000,
            hideProgressBar: true,
          }
        );
        router.push("/feed");
      } else if (message === "This account is blocked") {
        toast.info(t("cet utilisateur est déjà bloqué par l'administration"), {
          autoClose: 3000,
          hideProgressBar: true,
        });
      } else {
        toast.info(message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    } else {
      if (data) {
        const { id, email, role } = jwt(data?.access);
        dispatch(
          saveToken({
            access_token: data?.access,
            refresh_token: null,
            user: { id, email, role },
          })
        );
        if (role === "freelancer") {
          if (USER?.applicationStatus === "Verified") {
            router.push("/freelancerProfile");
          } else {
            router.push("/inscription");
          }
        } else {
          // router.push({
          //   pathname: `${redirect ? redirect : "/feed"}`,
          //   query: {
          //     freelancerId: freelancerId,
          //   },
          // });
          router.push(
            redirect
              ? {
                  pathname: `${redirect}`,
                  query: {
                    freelancerId: freelancerId,
                  },
                }
              : "/feed"
          );
        }
      }
    }
  };
  return (
    <div className="max-w-[1366px] h-screen mx-auto pt-[109px]">
      <div className="flex justify-center items-center h-full lg:py-[60px] px-[30px] py-[30px]">
        <div className="flex flex-col items-center gap-[30px] w-[400px] bg-white">
          <h2 className="font-bold text-[40px] leading-5 font-myriad-bold">
            {t("Connexion")}
          </h2>
          <form
            onSubmit={formik.handleSubmit}
            className="w-full flex flex-col gap-[15px] font-myriad-normal text-dark pt-8"
          >
            <div className="flex w-full flex-col gap-[9px] text-sm leading-5">
              <label htmlFor="email" className="capitalize">
                {t("adresse mail")}
              </label>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "16px",
                    fontFamily: "Myriad Pro",
                    height: "45px",
                    padding: "0px 16px",
                  },
                  "& .MuiFormHelperText-root": {
                    textAlign: `${router.locale === "ar" ? "right" : "left"}`,
                  },
                  // "&:hover fieldset": {
                  //   borderColor: "#923FB3",
                  // },

                  // "& input:valid:focus + fieldset": {
                  //   borderLeftWidth: 6,
                  //   borderColor: "#923FB3",
                  //   padding: "0px !important", // override inline-style
                  // },
                }}
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && t(formik.errors.email)}
                label=""
                variant="outlined"
                placeholder={t("Entrer votre email")}
                fullWidth
              />
            </div>
            <div className="flex w-full flex-col gap-[9px] text-sm leading-5">
              <label htmlFor="psw" className="capitalize">
                {t("Mot de passe")}
              </label>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "16px",
                    fontFamily: "Myriad Pro",
                    height: "45px",
                    padding: router.locale === "ar" ? "0px 16px" : "0px",
                  },
                  "& .MuiFormHelperText-root": {
                    textAlign: `${router.locale === "ar" ? "right" : "left"}`,
                  },
                }}
                id="psw"
                name="psw"
                value={formik.values.psw}
                onChange={formik.handleChange}
                error={formik.touched.psw && Boolean(formik.errors.psw)}
                helperText={formik.touched.psw && t(formik.errors.psw)}
                label=""
                type={showPassword ? "text" : "password"}
                variant="outlined"
                placeholder={t("Entrer le mot de passe")}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <div
                      className={`absolute  ${
                        router.locale === "ar"
                          ? "left-0 pl-[8px]"
                          : "right-0 pr-[16px]"
                      }`}
                    >
                      <InputAdornment
                        position={`${
                          router.locale === "ar" ? "start" : "end"
                        } `}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    </div>
                  ),
                }}
              />
            </div>
            <div className="flex justify-between flex-wrap items-center">
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "12px!important",
                    pt: "1px",
                    "&:hover": {
                      color: "#923FB3",
                    },
                  },
                }}
                control={
                  <Checkbox
                    size="small"
                    sx={{
                      "&.Mui-checked": {
                        color: "#923FB3",
                      },
                    }}
                  />
                }
                label={t("Enregistrer mes infos de connexion")}
              />
              <Link href="/request-reset-password">
                <span className="text-xs text-primary ha:hover:text-secondary">
                  {t("Mot de passe oublié ?")}
                </span>
              </Link>
            </div>
            <button
              type="submit"
              disabled={loading}
              className="bg-primary ha:hover:bg-secondary text-white font-myriad-semibold text-sm leading-5 w-full rounded font-semibold capitalize py-[12.5px]"
            >
              {loading ? <Spinner /> : t("Login")}
            </button>
            <div className="h-[0.4px] w-full bg-[#9C9C9C] mt-[30px]"></div>
            <div className="flex justify-center gap-2 items-center text-dark text-sm font-myriad-normal">
              <span>{t("Vous n'avez pas de compte ?")}</span>
              <Link href="/register">
                <span className="text-primary">{t("Créer un compte !")}</span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AuthForm;
