import React from "react";

//hoc
import Layout from "../hoc/Layout";

//components
import Header from "../components/Header";

//layouts
import AuthForm from "../layouts/AuthForm";

const Login = () => {
  return (
    <Layout title="Connexion">
      <Header active="login" />
      <AuthForm />
    </Layout>
  );
};

export default Login;
